import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WebCamService } from 'src/app/modules/force-platform/services/webcam.service';

declare var MediaRecorder: {
  prototype: MediaRecorder;
  new (stream: MediaStream, options?: MediaRecorderOptions): MediaRecorder;
};

@Component({
  selector: 'app-webcam-connexion',
  templateUrl: './webcam-connexion.component.html',
  styleUrls: ['./webcam-connexion.component.scss'],
  providers: [WebCamService],
})

/**
 * This component representes the informative div of the connexion with wiiBoard
 */
export class WebCamConnexionComponent implements OnInit, OnDestroy {
  @ViewChild('videoElement', { static: false }) videoElement!: ElementRef<HTMLVideoElement>;
  @ViewChild('downloadLink', { static: false }) downloadLink!: ElementRef<HTMLAnchorElement>;
  @Input() config: boolean;

  private stream: MediaStream | null = null;
  private mediaRecorder: MediaRecorder | null = null;
  private chunks: Blob[] = [];
  public webcamMessage: string;
  public configuredCameraId: string | null = null;
  public recordCamera: boolean = true;
  public cameraFound: boolean = false;
  public configMessage: string;
  public videoBlob: Blob;

  isRecording = false;

  constructor(private translateService: TranslateService, private webCamService: WebCamService) {}

  async ngOnInit(): Promise<void> {
    this.configuredCameraId = localStorage.getItem('configuredCameraId');
    if (this.configuredCameraId) {
      console.log('Caméra configurée trouvée :', this.configuredCameraId);
      await this.activateCamera(this.configuredCameraId);
    } else {
      this.cameraFound = false;
      this.webcamMessage = this.translateService.instant('webcam.notfound');
    }

    // Ajoutez un écouteur pour détecter les changements de périphériques
    navigator.mediaDevices.addEventListener('devicechange', this.handleDeviceChange);
  }

  private handleDeviceChange = async (): Promise<void> => {
    console.log('Changement détecté dans les périphériques');
    await this.checkAndActivateConfiguredCamera();
  };

  private async checkAndActivateConfiguredCamera(): Promise<void> {
    if (!this.configuredCameraId) {
      this.cameraFound = false;
      this.webcamMessage = this.translateService.instant('webcam.notfound');
      return;
    }

    // Lister tous les périphériques disponibles
    const devices = await navigator.mediaDevices.enumerateDevices();
    const matchingCamera = devices.find(
      (device) => device.kind === 'videoinput' && device.deviceId === this.configuredCameraId,
    );

    if (matchingCamera) {
      this.cameraFound = true;
      console.log('Caméra configurée trouvée et activée :', this.configuredCameraId);
      await this.activateCamera(this.configuredCameraId);
    } else {
      this.cameraFound = false;
      this.webcamMessage = this.translateService.instant('webcam.notfound');
      console.log("La caméra configurée n'est pas disponible.");
    }
  }

  ngAfterViewInit(): void {
    if (this.videoElement && this.videoElement.nativeElement) {
      try {
        // Logique pour activer la caméra
        console.log('Video element initialized:', this.videoElement.nativeElement);
      } catch (error) {
        console.error('Error during camera activation:', error);
      }
    } else {
      console.error('Video element is not defined');
    }
  }

  ngOnDestroy(): void {
    this.stopCamera();
    navigator.mediaDevices.removeEventListener('devicechange', this.handleDeviceChange);
  }

  async scanAndConfigureCamera(): Promise<void> {
    try {
      await navigator.mediaDevices.getUserMedia({ video: true });
      const devices = await navigator.mediaDevices.enumerateDevices();
      console.log('devices is ', devices);
      const videoDevices = devices.filter(
        (device) => device.label.includes('Logitech') && device.kind === 'videoinput',
      );

      if (videoDevices.length === 0) {
        this.webcamMessage = this.translateService.instant('webcam.notfound');
        console.error('Aucune caméra détectée.');
        return;
      }

      const selectedDeviceId = prompt(
        this.translateService.instant('webcam.selectcamera') +
          '\n' +
          videoDevices.map((device, index) => `${index + 1}: ${device.label}`).join('\n'),
      );

      const deviceIndex = parseInt(selectedDeviceId || '', 10) - 1;
      if (isNaN(deviceIndex) || deviceIndex < 0 || deviceIndex >= videoDevices.length) {
        this.webcamMessage = this.translateService.instant('webcam.invalidselection');
        return;
      }

      this.configuredCameraId = videoDevices[deviceIndex].deviceId;
      localStorage.setItem('configuredCameraId', this.configuredCameraId);

      await this.activateCamera(this.configuredCameraId);
    } catch (error) {
      console.error('Erreur lors de la configuration de la caméra :', error);
    }
  }

  disconnectCamera(): void {
    localStorage.removeItem('configuredCameraId');
    this.stopCamera();
    console.log('Caméra déconnectée et supprimée du localStorage.');
  }

  async activateCamera(deviceId: string): Promise<void> {
    try {
      this.cameraFound = true; // La caméra est trouvée
      this.stream = await navigator.mediaDevices.getUserMedia({
        video: { deviceId: { exact: deviceId } },
      });

      // Attendez que le DOM soit prêt avant d'accéder à videoElement
      setTimeout(() => {
        if (this.videoElement && this.videoElement.nativeElement) {
          this.videoElement.nativeElement.srcObject = this.stream;
          this.videoElement.nativeElement.play();
          console.log('Caméra activée.');
        } else {
          console.error('Element vidéo non trouvé.');
        }
      }, 0);
    } catch (error) {
      console.error('Erreur lors de l’activation de la caméra :', error);
      this.cameraFound = false;
      this.webcamMessage = this.translateService.instant('webcam.notfound');
    }
  }

  /*
  async scanAndSelectLogitechCamera(): Promise<MediaStream | null> {
    try {
      // 1. Scanner les périphériques disponibles
      const devices = await navigator.mediaDevices.enumerateDevices();
      
      // 2. Filtrer uniquement les caméras Logitech
      const logitechCameras = devices.filter(
        device => device.kind === 'videoinput' && device.label.toLowerCase().includes('logitech')
      );
  
      // Vérifiez s'il y a des caméras Logitech disponibles
      if (logitechCameras.length === 0) {
        console.error('Aucune caméra Logitech détectée.');
        return null;
      }
  
      // 3. Afficher une liste des caméras Logitech pour l'utilisateur
      const selectedDeviceId = prompt(
        'Sélectionnez une caméra Logitech :\n' +
          logitechCameras
            .map((device, index) => `${index + 1}: ${device.label || `Caméra ${index + 1}`}`)
            .join('\n')
      );
  
      const deviceIndex = parseInt(selectedDeviceId || '', 10) - 1;
  
      if (isNaN(deviceIndex) || deviceIndex < 0 || deviceIndex >= logitechCameras.length) {
        console.error('Périphérique sélectionné invalide.');
        return null;
      }
  
      // 4. Configurer les contraintes pour utiliser le périphérique sélectionné
      const constraints = {
        video: {
          deviceId: { exact: logitechCameras[deviceIndex].deviceId },
        },
      };
  
      // 5. Obtenir le flux vidéo du périphérique sélectionné
      const stream = await navigator.mediaDevices.getUserMedia(constraints);

      // 5. Afficher le flux dans une balise vidéo
      const videoElement = document.querySelector('video') as HTMLVideoElement;
      if (videoElement) {
        videoElement.srcObject = stream;
        videoElement.play();
      }
  
      console.log('Flux vidéo obtenu à partir de :', logitechCameras[deviceIndex].label);
      return stream;
    } catch (error) {
      console.error('Erreur lors du scan ou de la sélection des périphériques :', error);
      return null;
    }
  }*/

  async startCamera(): Promise<void> {
    try {
      // Vérifier si le navigateur supporte getUserMedia
      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        console.error('getUserMedia API non supportée par ce navigateur.');
        return;
      }
      // Récupérer les périphériques disponibles
      const devices = await navigator.mediaDevices.enumerateDevices();
      //const videoDevices = devices.filter(device => device.kind === 'videoinput');
      const videoDevices = devices.filter(
        (device) => device.kind === 'videoinput' && !device.label.toLowerCase().includes('logitech'),
      );
      // Vérifiez s'il y a des caméras disponibles
      if (videoDevices.length === 0) {
        this.webcamMessage = this.translateService.instant('webcam.notfound');
        return;
      }
      // Sélectionnez la première caméra (ou une caméra spécifique en fonction de vos critères)
      const selectedDeviceId = videoDevices[0].deviceId; // Changez l'index si vous voulez une autre caméra
      // Activer la caméra sélectionnée
      this.stream = await navigator.mediaDevices.getUserMedia({
        video: { deviceId: { exact: selectedDeviceId } },
      });

      // Afficher le flux vidéo dans la balise <video>
      this.videoElement.nativeElement.srcObject = this.stream;
      this.videoElement.nativeElement.play();

      console.log(`Caméra activée : ${videoDevices[0].label}`);
    } catch (error) {
      console.error('Erreur lors de l’accès à la caméra :', error);
    }
  }

  public async pushVideo(currentRecordingSessionId: string, dateShared: string) {
    const currentDate = new Date().toISOString();
    console.log('Body from componements type: %s, Body length: %d', typeof this.videoBlob, this.videoBlob.size || 0);
    //try {
    console.log('Start sending the video...');
    // Appelez le service
    const result = await this.webCamService
      .pushVideo(currentRecordingSessionId, currentDate, this.videoBlob)
      .toPromise();
    console.log('Push video with result :', result);
    return result;
    /*} catch (error) {
      console.error('Error pushing video:', error);
      return false;
    }*/
  }

  async startRecording() {
    console.log('this.recordCamera is ', this.recordCamera);
    if (this.stream && this.recordCamera) {
      await console.log('vider le blob avant de commencer');
      this.chunks = await [];
      this.mediaRecorder = await new MediaRecorder(this.stream, { mimeType: 'video/webm' });
      this.mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          this.chunks.push(event.data);
        }
      };
      //this.mediaRecorder.onstop = this.saveRecording.bind(this);
      await this.mediaRecorder.start();
      this.isRecording = await true;
    } else {
      console.error('Pas de flux disponible pour l’enregistrement.');
    }
  }

  async stopRecording(getVideo: boolean) {
    if (this.mediaRecorder) {
      this.mediaRecorder.stop();
      this.mediaRecorder.onstop = async () => {
        if (this.chunks.length === 0) {
          console.log('No data chunks available for the video.');
          return;
        }
        if (getVideo) {
          this.videoBlob = await new Blob(this.chunks, { type: 'application/octet-stream' });
          if (this.videoBlob.size === 0) {
            console.log('Le Blob est vide. Vérifiez les chunks.');
            return;
          } else if (this.recordCamera) {
            console.log('Le Blob a été bien récupéré.');
            return;
          }
        } else {
          console.log('vider la vidéo pour enregistrer une nouvelle');
          this.chunks = [];
          return;
        }
      };
    }
  }

  async SaveRecording(currentRecordingSessionId: string, dateShared: string): Promise<boolean> {
    if (this.videoBlob.size === 0) {
      console.error('Le Blob est vide. Vérifiez les chunks.');
      return false; // Échec car le Blob est vide
    } else {
      try {
        await this.pushVideo(currentRecordingSessionId, dateShared);
        return true; // Succès si l'upload s'est bien déroulé
      } catch (error) {
        console.error("Erreur lors de l'envoi de la vidéo:", error);
        return false; // Échec si une erreur survient
      }
    }
  }

  downloadRecording(): void {
    const blob = new Blob(this.chunks, { type: 'application/octet-stream' });
    //send recording to server
    const url = URL.createObjectURL(blob);
    const anchor = this.downloadLink.nativeElement;
    anchor.href = url;
    anchor.download = 'recording.webm';
    anchor.click();
    //this.chunks = []; // Réinitialise les données
  }

  stopCamera(): void {
    if (this.stream) {
      this.stream.getTracks().forEach((track) => track.stop());
      this.stream = null;
    }
    this.cameraFound = false;
    console.log('Caméra arrêtée.');
  }
}
