<div
  *ngIf="show"
  @state
  class="snackbar-wrap"
  [ngClass]="{ success: type === 'success', danger: type === 'danger', warning: type === 'warning' }"
>
  <div class="d-flex justify-content-between">
    <div></div>
    <p class="snackbar-text">
      {{ message }}
    </p>
    <button type="button" class="close" (click)="onCloseSnackBar()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>
