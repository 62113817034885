import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { delay, map, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { SensorConfiguration } from '../models/sensor-configuration.model';
import { BodyPosition } from '../models/body-position.model';
import { AnalysisParameters } from '../models/analysis-parameters.model';
import { AnalysisState, JobState } from '../models/analysis-state.model';
import { AnalysisResults } from '../models/analysis-results.model';
import { AnalysisResultsSemioInfo } from '../models/analysis-results-semioInfo.model';
import { AnalysisResultsDetailByAge } from '../models/analysis-results-detailByAge.model';
import * as medicalConst from 'src/app/tools/medical.constants';
import { AppConfigurationService } from 'src/app/app-configuration.service';

@Injectable()
export class RemoteInteraction {
  private static readonly getSensorConfigurationsPath = '/sensorsConfig';
  private static readonly startDataAnalysisPath = '/analysis/{guid}/start';
  private static readonly getAnalysisStatePath = '/analysis/{guid}/state';
  private static readonly discardSessionDataPath = '/analysis/{guid}';
  private static readonly getAnalysisResultsForSessionPath = '/analysis/{guid}/{lang}';
  private static readonly getAnalysisResultsForBilanPath = '/analysis/{bilanId}/{lang}';
  private static readonly persistData = '/{guid}/persist';
  private static readonly deleteData = '/{guid}';
  private static readonly stationId = 'fixmeStationId';

  public constructor(
    private http: HttpClient,
    private appConfigurationService: AppConfigurationService,
    private translateService: TranslateService,
  ) {}

  public async getSensorConfigurations(): Promise<SensorConfiguration[]> {
    console.log(this.constructor.name + ': fetching sensors configuration');

    const url = this.appConfigurationService.locomotionProxyBaseUri + RemoteInteraction.getSensorConfigurationsPath;

    const x = this.http
      .get(url)
      .pipe(
        map((j) => this.mapJsonToSensorConfiguration(j)),
        tap((res) => console.log(this.constructor.name + ': got this sensors configuration: ', res)),
      )
      .toPromise();

    return x;
  }

  public async discardSessionData(currentRecordingSessionId: string): Promise<void> {
    console.log(this.constructor.name + ': discarded recording for uuid: ', currentRecordingSessionId);

    let url = this.appConfigurationService.locomotionProxyBaseUri + RemoteInteraction.discardSessionDataPath;
    url = url.replace('{guid}', currentRecordingSessionId);

    let x = this.http.delete(url);
    x = this.toLog(x, 'discardSessionData(' + currentRecordingSessionId + ')');
    return this.toPromise(x);
  }

  public async startDataAnalysis(currentRecordingSessionId: string, parameters: AnalysisParameters): Promise<void> {
    console.log(this.constructor.name + ': started data analysis for uuid: ', currentRecordingSessionId);

    let url = this.appConfigurationService.locomotionProxyBaseUri + RemoteInteraction.startDataAnalysisPath;
    url = url.replace('{guid}', currentRecordingSessionId);

    let x = this.http.post(url, parameters);
    x = this.toLog(x, 'startDataAnalysis(' + currentRecordingSessionId + ')');
    return this.toPromise(x);
  }

  public async getAnalysisState(currentRecordingSessionId: string): Promise<AnalysisState> {
    console.log(this.constructor.name + ': fetching state for analysis with uuid: ', currentRecordingSessionId);

    let url = this.appConfigurationService.locomotionProxyBaseUri + RemoteInteraction.getAnalysisStatePath;
    url = url.replace('{guid}', currentRecordingSessionId);

    const x = this.http
      .get(url)
      .pipe(map((j) => this.mapJsonToAnalysisState(j)))
      .toPromise();

    return x;
  }

  public async getAnalysisResultsForSession(currentRecordingSessionId: string): Promise<AnalysisResults> {
    //console.log('getAnalysisResultsForSession');
    console.log(this.constructor.name + ': fetching analysis data for uuid: ', currentRecordingSessionId);
    const lang = this.translateService.currentLang;

    let url = this.appConfigurationService.locomotionProxyBaseUri + RemoteInteraction.getAnalysisResultsForSessionPath;
    url = url.replace('{guid}', currentRecordingSessionId);
    url = url.replace('{lang}', lang);
    const x = this.http
      .get(url)
      .pipe(map((j) => this.mapJsonToAnalysisData(j)))
      .toPromise();
    return x;
  }

  public async getAnalysisResultsForBilan(bilanId: number): Promise<AnalysisResults> {
    console.log('getAnalysisResultsForBilan');
    console.log(this.constructor.name + ': fetching analysis data for bilanId: ', bilanId);
    const lang = this.translateService.currentLang;

    let url = this.appConfigurationService.locomotionProxyBaseUri + RemoteInteraction.getAnalysisResultsForBilanPath;
    url = url.replace('{bilanId}', '' + bilanId);
    url = url.replace('{lang}', lang);

    const x: Promise<AnalysisResults> = this.http
      .get(url)
      .pipe(map((j) => this.mapJsonToAnalysisData(j)))
      .toPromise();

    return x;
  }

  public async persistAnalysisResults(currentRecordingSessionId: string, bilanId: number): Promise<void> {
    console.error(
      this.constructor.name +
        ': DUMMY METHOD CALL: persistAnalysisResults(' +
        currentRecordingSessionId +
        ', ' +
        bilanId +
        ')',
    );

    /** DUMMY MOCK CRAP */

    await of(true)
      .pipe(delay(2000))
      .toPromise();

    /** persistData */
    /*
    const parameters = {bilanId, stablizationHelpId, footwearId, distance}
    let url = this.appConfigurationService.locomotionProxyBaseUri + RemoteInteraction.persistData;
    url = url.replace('{guid}', '' + currentRecordingSessionId);
 
    const x: Promise<void> = this.http
      .post(url, parameters)
      .toPromise();

    return x;*/
  }

  public async getJsonModel(url: string) {
    const x: Promise<any> = this.http
      .get(url)
      .pipe(
        map((r) => {
          //console.log('Json reçu #1', r);
          return r;
        }),
      )
      .toPromise();

    return x;
  }

  private mapJsonToAnalysisData(data: any): AnalysisResults {
    const out = new AnalysisResults();
    this.mapVariables(data, out);
    this.mapModels(data, out);
    this.mapGraphes(data, out);

    return out;
  }

  private mapVariables(data: any, out: AnalysisResults) {
    const variables = data.features.ListVariables as any[];

    // map data.features.ListVariables into an array of number
    const values: { [k: string]: number } = variables.reduce((prev: { [k: string]: number }, current) => {
      prev[current.Variable] = current.Values[0];
      return prev;
    }, {});

    out.AvgSpeed = values.AvgSpeed;
    out.CycleVariability = values.CycleVariability;
    out.DoubleStance = values.DoubleStance;
    out.MeanStepDuration = values.MeanStepDuration;
    out.RoliTronc = values.RoliTronc;
    out.SwingMeanStrideDuration = values.SwingMeanStrideDuration;

    // FIXME
    out.distance = medicalConst.DISTANCE_TO_LOCOMOTION;
  }

  private mapModels(data: any, out: AnalysisResults) {
    const models = data.model;
    for (const key in models) {
      if (models.hasOwnProperty(key)) {
        out.models[key] = models[key];
      }
    }
  }

  private mapGraphes(data: any, out: AnalysisResults) {
    const v = {
      loco: data.loco,
      rota: data.rota,
      video: data.video,
      semioAll: data.semioall,
      semio18_39: data.semio[0],
      semio40_59: data.semio[1],
      semio60_79: data.semio[2],
      semio80_99: data.semio[3],
      semio100_200: data.semio[4],
      semioInfo: this.mapsemioInfo(data.semioInfo),
    };

    out.graphes = v;
  }

  private mapsemioInfo(data: any): AnalysisResultsSemioInfo {
    if (data === undefined || data === null) {
      return;
    }
    const out = new AnalysisResultsSemioInfo();
    out.avgLocoLeft = data.avgLocoLeft;
    out.avgLocoRightLeft = data.avgLocoRightLeft;
    out.avgLocoRight = data.avgLocoRight;
    out.rightStep = data.rightStep;
    out.leftStep = data.leftStep;
    out.uTurn = data.uTurn;
    out.detailByAge = (data.detailByAge as any[]).map((d) => this.mapDetailByAge(d));

    return out;
  }

  private mapDetailByAge(element: any): AnalysisResultsDetailByAge {
    const out = new AnalysisResultsDetailByAge();
    out.name = element.name;
    out.ageMin = element.ageMin;
    out.ageMax = element.ageMax;
    out.avgSpeed = element.avgSpeed;
    out.fluidity = element.fluidity;
    out.regularity = element.regularity;
    out.pace = element.pace;
    out.stability = element.stability;
    out.symmetry = element.symmetry;
    out.synchronization = element.synchronization;
    out.vigor = element.vigor;

    return out;
  }

  private mapJsonToAnalysisState(stateData: any): AnalysisState {
    const state = this.getJobState(stateData.state as string);
    const output = { jobState: state, errorCode: stateData.error };
    console.log(this.constructor.name + ': mapJsonToAnalysisState(): ', output);
    return output;
  }

  private getJobState(state: string): JobState {
    switch (state) {
      case 'running':
        return JobState.Running;
      case 'done':
        return JobState.Done;
      case 'error':
        return JobState.Error;
      default:
        throw new Error('Unsupported value for ' + typeof JobState + ': ' + state);
    }
  }

  private mapJsonToSensorConfiguration(sensorData: any): SensorConfiguration[] {
    const out: SensorConfiguration[] = [];
    for (let i = 0; i < sensorData.idSensors.length; i++) {
      const idSensor = sensorData.idSensors[i];
      const position = this.getBodyPosition(sensorData.sensors[i]);
      const sc = new SensorConfiguration(RemoteInteraction.stationId, idSensor, position);
      out.push(sc);
    }

    return out;
  }

  private getBodyPosition(position: string): BodyPosition {
    switch (position) {
      case 'Tete':
        return BodyPosition.Head;
      case 'Pied gauche':
        return BodyPosition.LeftFoot;
      case 'Pied Droit':
        return BodyPosition.RightFoot;
      case 'Ceinture':
        return BodyPosition.Hips;
      default:
        throw new Error('Unsupported value for ' + typeof BodyPosition + ': ' + position);
    }
  }

  private toLog(source: Observable<any>, where: string): Observable<any> {
    return source.pipe(
      tap((r) => {
        console.log(this.constructor.name + ': (HTTP) ' + where + ': ' + r);
        return r;
      }),
    );
  }

  private toPromise(source: Observable<any>): Promise<void> {
    return source
      .pipe(
        map((r) => {
          /**/
        }),
      )
      .toPromise();
  }
}
