import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { PatientDataService } from 'src/app/modules/abilycare-data-access/api/patient-data.service';
import { AuthService } from 'src/app/modules/core/services/authentification/auth.service';
import { RolesCheckService } from 'src/app/modules/core/services/authentification/roles-check.service';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { Router } from '@angular/router';
import { of, Subscription, throwError } from 'rxjs';
import { animate, state, style, trigger, transition } from '@angular/animations';
import { MatTableDataSource } from '@angular/material';
import * as moment from 'moment';
import { AppConfigurationService } from 'src/app/app-configuration.service';
import { catchError, delay } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import {
  Bilans,
  BilansControllerService,
  CourseControllerService,
  Export,
  FileDownloadControllerService,
  GripControllerService,
  PatientBrief,
  PatientDetail,
  QuestionnaireReplyControllerService,
  RombergControllerService,
  WalkingControllerService,
  WeighingControllerService,
} from '@abilycare/dal-client';
import { LocalDatePipe } from 'src/app/modules/time/pipes/localdate.pipe';
import { RemoteInteraction as RemoteInteractionRomberg } from 'src/app/modules/romberg/services/remote-interaction.service';
import { RemoteInteraction as RemoteInteractionWalk } from 'src/app/modules/walk/services/remote-interaction.service';
import { until } from 'protractor';

export interface bilanDetails {
  testId: number;
  testType: number;
  testName: string;
  testDate: Date;
  isSelected: boolean;
  courseId: number;
  hasVideo: boolean;
}

export interface bilansToExport {
  patientId: number;
  name: string;
  folderNumber: string;
  pattat: any;
  birthDate: Date;
  lastTest: number;
  lastTestDate: Date;
  listBilansDetails: bilanDetails[];
  isSelected: boolean;
}

@Component({
  selector: 'app-bilans',
  templateUrl: './bilans.component.html',
  styleUrls: ['./bilans.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class BilansComponent implements OnInit, OnDestroy {
  @ViewChild('btnClose', { static: true }) btnClose: ElementRef;
  @ViewChild('btnCancelExport', { static: true }) btnCancelExport: ElementRef;
  @ViewChild('btnCloseFilter', { static: true }) btnCloseFilter: ElementRef;
  @ViewChild('btnNoExport', { static: true }) btnNoExport: ElementRef;

  @ViewChild('btnCloseExportInProgress', { static: true }) btnCloseExportInProgress: ElementRef;

  @Input() searchPatient: string;

  dataSource;

  columnsToDisplay = ['patientId', 'name', 'birthDate', 'lastTest', 'lastTestDate'];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedElement: PatientDetail | null;

  public patients: Array<bilansToExport> = new Array<bilansToExport>();
  public allPatientsList: Array<bilansToExport> = new Array<bilansToExport>();
  public id: number;

  public hasFilterApplicated: boolean = false;
  public allCheckboxChecked: boolean = false;
  public allCheckboxIndeterminate: boolean = false;
  public checkedCount: number = 0;
  public isModalOpened: boolean = false;
  public searchText;
  public canHide: boolean;
  public checkboxList = [];
  public selectedPatient: PatientBrief = null;
  public testListName = [];

  private subscriptions: Subscription[] = [];

  public bilanListToExport: any[] = [];
  public patientListToExport: number[] = [];
  public bilanListPatientToExport: number[] = [];
  public bilanListTypeToExport: number[] = [];
  public bilanListNameToExport: string[] = [];
  public bilanListDateToExport: Date[] = [];
  public bilanAnalysisDetailList: any[] = [];
  public exportRunning: boolean = false;
  public exportFinished: boolean = false;
  public exportError: boolean = false;
  public anonymousData: boolean = true;
  public exportRawData: boolean;
  public exportStatus: string;
  public lastExtraction: Export;
  public practionnerMeId: number;

  public selectionTitle: string;
  public filterValue: string = '';

  public endDate: Date;
  public startDate: Date;

  public hasRoleExportData: boolean = false;
  public startExportDate: Date;

  public typeExport: number = 0;
  public getAllAnalysisData: boolean = true;
  public lang: string = 'fr';

  constructor(
    private patientData: PatientDataService,
    private fileTransfertService: FileDownloadControllerService,
    private bilansService: BilansControllerService,
    private dataService: DataProviderService,
    public rolesCheck: RolesCheckService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private snackBarService: SnackBarService,
    private translateService: TranslateService,
    private authService: AuthService,
    private appConfigurationService: AppConfigurationService,
    private rombergService: RombergControllerService,
    private walkingService: WalkingControllerService,
    private gripService: GripControllerService,
    private weighingService: WeighingControllerService,
    private questService: QuestionnaireReplyControllerService,
    private courseService: CourseControllerService,
    private localDatePipe: LocalDatePipe,
    private remoteInteractionRomberg: RemoteInteractionRomberg,
    private remoteInteractionWalk: RemoteInteractionWalk,
  ) {
    this.dataService.storeHeaderShowed(true);
    this.testListName = [
      '',
      'Course.filter.questionnary.result',
      'Course.filter.romberg.result',
      'Course.filter.walking.result',
      'Course.filter.grip.result',
      'Course.filter.weight.result',
    ];
    this.checkboxList = [
      {
        id: 1,
        name: _('Course.filter.questionnary.result'),
        disabled: false,
        checked: false,
        labelPosition: 'after',
        isValidated: false,
      },
      {
        id: 2,
        name: _('Course.filter.romberg.result'),
        disabled: false,
        checked: false,
        labelPosition: 'after',
        isValidated: false,
      },
      {
        id: 3,
        name: _('Course.filter.walking.result'),
        disabled: false,
        checked: false,
        labelPosition: 'after',
        isValidated: false,
      },
      {
        id: 4,
        name: _('Course.filter.grip.result'),
        disabled: false,
        checked: false,
        labelPosition: 'after',
        isValidated: false,
      },
      {
        id: 5,
        name: _('Course.filter.weight.result'),
        disabled: false,
        checked: false,
        labelPosition: 'after',
        isValidated: false,
      },
      {
        id: 6,
        name: _('Course.filter.result'),
        disabled: false,
        checked: false,
        labelPosition: 'after',
        isValidated: false,
      },
    ];
  }

  childCheck(parentObj, childObj) {
    parentObj.isSelected = childObj.every(function(itemChild: any) {
      return itemChild.isSelected == true;
    });
    this.updateList();
  }

  //Click event on child checkbox
  checkBilan(bilan) {
    bilan.isSelected = true;
    //element.isSelected = true;
    this.updateList();
  }

  parentCheck(parentObj) {
    for (var i = 0; i < parentObj.listBilansDetails.length; i++) {
      parentObj.listBilansDetails[i].isSelected = parentObj.isSelected;
    }
    this.updateList();
  }

  getAge(birthdate): string {
    const years: number = moment().diff(birthdate, 'years');
    if (years >= 0) return years.toString() + ' ' + this.translateService.instant('ans');
    return '-';
  }

  avoidListSelectionPatient() {
    this.allPatientsList.forEach((row) => {
      row.isSelected = false;
      row.listBilansDetails.forEach((bilan) => {
        bilan.isSelected = false;
      });
    });
  }

  async applyFilter(filterValue: string) {
    this.patientListToExport = []; //avoid patientList to Export
    this.bilanListToExport = []; //avoid bilanList
    this.bilanListPatientToExport = [];
    this.bilanListTypeToExport = [];
    this.bilanListNameToExport = [];
    this.bilanListDateToExport = [];
    this.selectionTitle = this.translateService.instant('select.all'); //restrat select.all
    this.avoidListSelectionPatient(); //avoid all selected item row and bilan
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
    this.patients = this.allPatientsList.filter((item: any) => {
      return (
        item.name.toLowerCase().includes(filterValue.toLowerCase()) ||
        item.folderNumber.toLowerCase().includes(filterValue.toLowerCase())
      );
    });
    this.updateList();
  }

  toggleRow(element: { expanded: boolean }) {
    // Uncommnet to open only single row at once
    // ELEMENT_DATA.forEach(row => {
    //   row.expanded = false;
    // })
    element.expanded = !element.expanded;
  }

  async updateList() {
    this.bilanListToExport = [];
    this.patientListToExport = [];
    this.bilanListPatientToExport = [];
    this.bilanListTypeToExport = [];
    this.bilanListNameToExport = [];
    this.bilanListDateToExport = [];
    this.bilanAnalysisDetailList = [];
    for (var i = 0; i < this.patients.length; i++) {
      //if (this.patients[i].isSelected) this.patientListToExport.push(this.patients[i].patientId);
      for (var j = 0; j < this.patients[i].listBilansDetails.length; j++) {
        if (this.patients[i].listBilansDetails[j].isSelected) {
          this.bilanListToExport.push(this.patients[i].listBilansDetails[j].testId);
          this.bilanListPatientToExport.push(this.patients[i].patientId);
          this.bilanListTypeToExport.push(this.patients[i].listBilansDetails[j].testType);
          this.bilanListNameToExport.push(this.patients[i].listBilansDetails[j].testName);
          this.bilanListDateToExport.push(new Date(this.patients[i].listBilansDetails[j].testDate.toLocaleString()));
          if (this.typeExport == 2) {
            this.getAnalysesData(
              this.patients[i].listBilansDetails[j].testId,
              this.patients[i].listBilansDetails[j].testType,
              new Date(this.patients[i].listBilansDetails[j].testDate.toLocaleString()),
              this.patients[i].patientId,
            );
          }
          if (!this.patientListToExport.includes(this.patients[i].patientId))
            this.patientListToExport.push(this.patients[i].patientId);
        }
        this.btnCancelExport.nativeElement.click();
      }
    }
  }

  selectunSelectAllRows() {
    this.patientListToExport = [];
    this.bilanListToExport = [];
    this.bilanListPatientToExport = [];
    this.bilanListTypeToExport = [];
    this.bilanListNameToExport = [];
    //console.log("row in select unselect ", this.patients);
    this.patients.forEach((row) => {
      row.isSelected = !row.isSelected;
      row.listBilansDetails.forEach((bilan) => {
        bilan.isSelected = !bilan.isSelected;
      });
      if (row.isSelected) this.selectionTitle = this.translateService.instant('deselect.all');
      else this.selectionTitle = this.translateService.instant('select.all');
    });
    this.updateList();
  }

  ngOnInit() {
    this.refresh();
    this.anonymousData = true;
    this.exportRawData = false;
    this.rolesCheck.hasExportRawData.subscribe((value) => {
      this.hasRoleExportData = value;
    });
    this.lang = this.translateService.currentLang;
  }

  public getPatientDetail(patientId: number) {
    this.id = patientId;
    this.subscriptions.push(
      this.patientData.getPatientById(patientId).subscribe(
        (patient) => {
          this.dataService.setPatient(patient);
          this.goTo('/Patient/' + patientId);
        },
        (error) => {
          console.error('BilansComponent : Err-Patient-01 ', error);
          const errMsg = this.translateService.instant('Err-Patient-01');
          this.snackBarService.show(errMsg, 'danger');
          console.error('Error getPatientById : ', error);
        },
      ),
    );
  }

  public refresh(): void {
    this.subscriptions.push(
      this.activatedRoute.paramMap.subscribe((params) => {
        this.getAllBilansPatients();
      }),
    );
  }

  public async getAllBilansPatients() {
    this.patientListToExport = [];
    this.bilanListToExport = [];
    this.bilanListPatientToExport = [];
    this.bilanListTypeToExport = [];
    this.bilanListNameToExport = [];
    const that = this;
    this.patients = new Array<bilansToExport>();
    const courseChecked: boolean = this.checkboxList[5].checked;
    this.subscriptions.push(
      this.bilansService.bilansControllerFindAllBilans().subscribe((allBilans) => {
        var resultArray = Object.keys(allBilans).map(function(bilanIndex) {
          let bilanObj = allBilans[bilanIndex];
          bilanObj.listBilansDetails.forEach((element) => {
            element.isSelected = false;
          });
          if (that.startDate) {
            let startDate = new Date(that.startDate);
            bilanObj.listBilansDetails = bilanObj.listBilansDetails.filter(function(e) {
              return new Date(e.testDate) >= startDate;
            });
          }
          if (that.endDate) {
            let endDateWithTime = new Date(that.endDate);
            endDateWithTime.setHours(23, 59, 59, 999); // Fin de journée

            bilanObj.listBilansDetails = bilanObj.listBilansDetails.filter(function(e) {
              let testDate = new Date(e.testDate);
              return testDate <= endDateWithTime;
            });
          }
          if (courseChecked) {
            bilanObj.listBilansDetails = bilanObj.listBilansDetails.filter(function(e) {
              return e.courseId && e.courseId > -1;
            });
          }
          bilanObj.isSelected = false;
          that.patients.push(bilanObj);
        });
        this.patients = this.patients.filter(function(e) {
          return e.listBilansDetails.length > 0;
        });
        that.dataSource = new MatTableDataSource(this.patients);
        this.allPatientsList = this.patients;
        if (this.filterValue) this.applyFilter(this.filterValue);
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public allCheckboxChange(): void {
    for (const value of Object.values(this.checkboxList)) {
      value.checked = this.allCheckboxChecked;
    }
  }

  public listChange(): void {
    let checkedCount = 0;
    // Get total checked items
    for (const value of Object.values(this.checkboxList)) {
      if (value.checked) checkedCount++;
    }

    this.checkedCount = checkedCount;

    if (checkedCount > 0 && checkedCount < this.checkboxList.length) {
      this.allCheckboxIndeterminate = true;
    } else if (checkedCount === this.checkboxList.length) {
      this.allCheckboxIndeterminate = false;
      this.allCheckboxChecked = true;
    } else {
      this.allCheckboxIndeterminate = false;
      this.allCheckboxChecked = false;
    }
  }

  public onStartDateChanged(value: any) {
    this.startDate = value;
    this.onSubmit();
  }

  public onEndDateChanged(value: any) {
    this.endDate = value;
    this.onSubmit();
  }

  public getPatientsWithFilter(tab: any): void {
    this.patientListToExport = [];
    this.bilanListToExport = [];
    this.bilanListPatientToExport = [];
    this.bilanListTypeToExport = [];
    this.bilanListNameToExport = [];
    const that = this;
    this.patients = [];
    const quest: boolean = tab[0].checked;
    const romberg: boolean = tab[1].checked;
    const walking: boolean = tab[2].checked;
    const grip: boolean = tab[3].checked;
    const weight: boolean = tab[4].checked;

    const courseChecked = tab[5].checked;

    this.subscriptions.push(
      this.bilansService
        .bilansControllerFindAllBilans(this.filterValue, grip, romberg, walking, weight, quest)
        .subscribe((allBilans) => {
          var resultArray = Object.keys(allBilans).map(function(bilanIndex) {
            let bilanObj = allBilans[bilanIndex];
            bilanObj.listBilansDetails.forEach((element) => {
              element.isSelected = false;
            });
            if (that.startDate) {
              let startDate = new Date(that.startDate);
              bilanObj.listBilansDetails = bilanObj.listBilansDetails.filter(function(e) {
                return new Date(e.testDate) >= startDate;
              });
            }
            if (that.endDate) {
              let endDateWithTime = new Date(that.endDate);
              endDateWithTime.setHours(23, 59, 59, 999); // Fin de journée

              bilanObj.listBilansDetails = bilanObj.listBilansDetails.filter(function(e) {
                let testDate = new Date(e.testDate);
                return testDate <= endDateWithTime;
              });
            }
            if (courseChecked) {
              bilanObj.listBilansDetails = bilanObj.listBilansDetails.filter(function(e) {
                return e.courseId && e.courseId > -1;
              });
            }
            bilanObj.isSelected = false;
            that.patients.push(bilanObj);
          });
          this.patients = this.patients.filter(function(e) {
            return e.listBilansDetails.length > 0;
          });
          this.dataSource = new MatTableDataSource(this.patients);
          this.allPatientsList = this.patients;
          if (this.filterValue) this.applyFilter(this.filterValue);
        }),
    );
  }

  public closeModalExportBilan(): void {
    this.btnCloseExportInProgress.nativeElement.click();
  }

  public closeModalExportInPorgress(): void {
    this.btnCancelExport.nativeElement.click();
  }

  // Start Export wi th checked List
  startExport() {
    this.exportRunning = true;
    this.startExportDate = new Date();
    this.exportData();
    this.btnCancelExport.nativeElement.click();
  }

  sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  public async onChangeTypeExport(value: string) {
    this.typeExport = parseFloat(value);
    if (this.typeExport == 2 || this.typeExport == 4) {
      //désélcetionné tt ce qui n'est pas romberg or walk

      this.bilanAnalysisDetailList = [];
      //open modal
      this.getAllAnalysisData = await false;
      this.anonymousData = false;
      await Promise.all([await this.getAllAnalaysesData(), await this.sleep(this.bilanListToExport.length * 500)]);
      this.getAllAnalysisData = await true;
    }
  }

  async getAllAnalaysesData() {
    return new Promise<void>((resolve, reject) => {
      const promises: Promise<void>[] = [];
      let i: number;
      for (i = 0; i < this.bilanListToExport.length; i++) {
        // Push each promise into the array
        promises.push(
          this.getAnalysesData(
            this.bilanListToExport[i],
            this.bilanListTypeToExport[i],
            this.bilanListDateToExport[i],
            this.bilanListPatientToExport[i],
          ),
        );
      }
      // Use Promise.all to wait for all promises to settle
      Promise.all(promises)
        .then(() => {
          // All promises have resolved, now resolve the outer promise
          resolve();
        })
        .catch((error) => {
          // If any promise rejects, reject the outer promise
          reject(error);
        });
    });
  }

  async getAnalysesData(bilanId, bilanType, bilanDate, patientId) {
    if (bilanType === 3) {
      try {
        this.remoteInteractionWalk.getAnalysisResultsForBilan(bilanId).then((value) => {
          const obj = {
            patientId: patientId,
            bilanId: bilanId,
            type: 'Walk',
            bilanDate: moment(bilanDate).format('YYYY-MM-DD HH:mm:ss'),
            bilanAnalysisDetail: value,
          };
          this.bilanAnalysisDetailList.push(obj);
        });
      } catch (Error) {
        console.log('information for bilan ', bilanId, " doesn't exist ");
      }
    } else if (bilanType === 2) {
      try {
        this.remoteInteractionRomberg.getAnalysisResultsForBilan(bilanId).then((value) => {
          const obj = {
            patientId: patientId,
            bilanId: bilanId,
            type: 'Romberg',
            bilanDate: moment(bilanDate).format('YYYY-MM-DD HH:mm:ss'),
            bilanAnalysisDetail: value,
          };
          this.bilanAnalysisDetailList.push(obj);
        });
      } catch (Error) {
        console.log('information for bilan ', bilanId, " doesn't exist ");
      }
    }
  }

  async exportData() {
    if (this.typeExport == 2) {
      await console.log('this.bilanAnalysisDetail is ', this.bilanAnalysisDetailList);
      await this.bilansService
        .bilansControllerExportBilansReports(this.patientListToExport.toString(), this.bilanAnalysisDetailList)
        .subscribe(
          (p) => {
            this.exportStatus = 'RUNNING';
            this.getExportStatus();
          },
          (err) => {
            console.log('BilansComponent : message is ', err);
            const errMsg = this.translateService.instant(err.error.error.message);
            this.snackBarService.show(errMsg, 'danger');
            console.log('Error Export ', err);
          },
        );
    } else {
      this.subscriptions.push(
        this.bilansService
          .bilansControllerExportBilans(
            this.patientListToExport.toString(),
            this.bilanListToExport.toString(),
            this.anonymousData,
            this.typeExport,
            this.lang,
          )
          .subscribe(
            (p) => {
              this.exportStatus = 'RUNNING';
              this.getExportStatus();
            },
            (err) => {
              console.log('BilansComponent : message is ', err);
              const errMsg = this.translateService.instant(err.error.error.message);
              this.snackBarService.show(errMsg, 'danger');
              console.log('Error Export ', err);
            },
          ),
      );
    }
  }

  private async wait(millis: number) {
    return of(true)
      .pipe(delay(millis))
      .toPromise();
  }

  async getExportStatus() {
    this.bilansService.bilansControllerExportStatus().subscribe((e) => {
      if (e.running) {
        this.getExportStatus();
      } else {
        this.lastExtraction = e;
        this.updateExportStatus();
      }
    });
  }

  updateExportStatus() {
    if (!this.lastExtraction || this.lastExtraction.error != '') {
      this.exportRunning = false;
      this.exportError = true;
      this.exportFinished = false;
    } else if (this.lastExtraction && !this.lastExtraction.running) {
      this.exportRunning = false;
      this.exportError = false;
      this.exportFinished = true;
    } else if (this.lastExtraction && this.lastExtraction.running) {
      this.exportRunning = true;
      this.exportError = false;
      this.exportFinished = false;
    }
  }

  downloadExport() {
    const fileName = 'exp-' + this.lastExtraction.doctorId + '.zip';
    const exportDate = this.localDatePipe.parse(this.startExportDate, 'dd_MM_yyyy_HH:mm:ss');
    const fileNametoSave = 'export_abilycare_' + exportDate + '.zip';
    this.fileTransfertService.fileDownloadControllerDownloadFile(fileName).subscribe((data) => {
      saveAs(data, fileNametoSave);
    });
  }

  public viewCourseObject(courseObject: bilanDetails): void {
    if (courseObject.testType == 3) {
      this.walkingService.walkingControllerFindById(courseObject.testId).subscribe((res) => {
        this.router.navigateByUrl('/courses/fried/walkView?fromBilan=1', { state: res });
      });
    } else if (courseObject.testType == 2) {
      this.rombergService.rombergControllerFindById(courseObject.testId).subscribe((res) => {
        this.router.navigateByUrl('/courses/fried/rombergView?fromBilan=1', { state: res });
      });
    } else if (courseObject.testType == 5) {
      this.weighingService.weighingControllerFindById(courseObject.testId).subscribe((res) => {
        this.router.navigateByUrl('/courses/fried/weightView?fromBilan=1', { state: res });
      });
    } else if (courseObject.testType == 4) {
      this.gripService.gripControllerFindById(courseObject.testId).subscribe((res) => {
        this.router.navigateByUrl('/courses/fried/gripView?fromBilan=1', { state: res });
      });
    } else if (courseObject.testType == 1) {
      this.questService.questionnaireReplyControllerFindById(courseObject.testId).subscribe((res) => {
        this.router.navigateByUrl('/courses/fried/questionnaryView?fromBilan=1', { state: res });
      });
    }
  }

  public closeModalFilter(): void {
    for (const value of Object.values(this.checkboxList)) {
      if (value.checked && !value.isValidate) {
        value.checked = false;
        this.allCheckboxChecked = false;
      } else if (!value.checked && value.isValidate) {
        value.checked = true;
      }
    }
  }

  public onSubmit(): void {
    this.patients = new Array<bilansToExport>();
    for (const value of Object.values(this.checkboxList)) {
      if (value.checked) {
        value.isValidate = true;
      } else {
        value.isValidate = false;
      }
    }
    if (!this.allCheckboxIndeterminate) {
      this.getAllBilansPatients();
      this.hasFilterApplicated = false;
    } else if (this.checkboxList[5].checked && this.checkedCount == 1) {
      this.getAllBilansPatients();
      this.hasFilterApplicated = true;
    } else {
      this.getPatientsWithFilter(this.checkboxList);
      this.hasFilterApplicated = true;
    }
    this.btnCloseFilter.nativeElement.click();
    this.isModalOpened = false;
  }

  public onRefreshInformation(): void {
    this.refresh();
  }

  goTo(page) {
    //this.selectPage.emit(page);
    this.router.navigate([page]);
  }
}
