<div *ngIf="!isWalkValidate">
  <app-custom-navbar
    [patient]="patient"
    [returnTitle]="returnTitle"
    (selectPage)="onSelectPage($event)"
  ></app-custom-navbar>
  <app-courses-breadcrumb-navbar [testName]="testName | translate" [course]="course"></app-courses-breadcrumb-navbar>
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-3 p-0">
        <app-fried-navbar
          [currentPage]="'walk'"
          (selectPage)="onSelectPage($event)"
          [course]="course"
        ></app-fried-navbar>
      </div>

      <div *ngIf="!canDisplayAssessment" class="col-md-9 fried-info">
        <div class="row">
          <div class="col-md-11  full-size-content-component" style="font-weight: normal;">
            <img
              class="d-inline-block align-top"
              width="20"
              height="20"
              src="assets/images/icons/info-circle.svg"
              alt=""
            />
            {{ 'app.walk.protocol.followStep' | translate }}
          </div>
        </div>

        <div class="row">
          <div class="col-md-7 simple-content-component flex-column justify-content-between">
            <div style="margin-bottom: 5px;">
              {{ 'app.walk.protocol.stepTitle' | translate }}
            </div>
            <div class="protocol-step d-flex justify-content-center">
              <img class="d-inline-block img-tutorial" src="assets/images/images/walk_tutorial_{{ lang }}.png" alt="" />
            </div>
            <div class="tutorial-button-container">
              <button
                (click)="resetModal()"
                class="btn btn-tertiary"
                data-target="#walkTutorialModal"
                data-toggle="modal"
              >
                {{ 'app.weight.protocol.tutorialLink' | translate }}
              </button>
            </div>
          </div>
          <div class="col-md-4 simple-content-component d-flex flex-column justify-content-between">
            <div>{{ 'app.walk.sensors.states' | translate }}</div>
            <div class="img-container">
              <img src="assets/images/images/sensors.png" alt="" />
            </div>
            <div class="sensor-container">
              <app-forceplatform-sensor-connexion (canLaunchTest)="onCanLaunchTest($event)">
              </app-forceplatform-sensor-connexion>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4 simple-content-component" style="height: 550px;">
            <div>{{ 'app.walk.information' | translate }}</div>
            <div class="preamble-walk">
              <div class="row">
                <div class="col-lg-12">
                  <span class="page-sub-title">{{ 'doubleTask' | translate }}</span>
                  <select [ngModel]="doubleTask" (ngModelChange)="setDoubleTask($event)">
                    <option *ngFor="let task of doubleTaskList" [ngValue]="task.id">{{ task.label }}</option>
                  </select>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <app-checkup-shoes-selector
                    [value]="shoesSelectorValues"
                    [disabled]="isTestStarted"
                    (valueChange)="onShoesSelectorValuesChange($event)"
                  ></app-checkup-shoes-selector>
                </div>
              </div>
            </div>
          </div>
          <div
            [ngClass]="{
              'col-md-3': cameraConfigured,
              'col-md-7': !cameraConfigured,
              'simple-content-component': true
            }"
          >
            <div>
              <div>{{ 'app.walk.mesurement' | translate }}</div>
            </div>

            <div
              *ngIf="!isTestStarted && !isTestStoped"
              class="justify-content-center align-content-center button-container-start"
            >
              <button
                class="btn btn-fifth general-button"
                *ngIf="!isTestFinish"
                [disabled]="!isTestAvailable"
                (click)="startTest()"
              >
                {{ 'app.walk.startTest' | translate }}
              </button>

              <button
                class="btn btn-fifth general-button"
                *ngIf="isTestFinish"
                [disabled]="!isTestAvailable"
                (click)="startTest()"
              >
                {{ 'app.test.restart' | translate }}
              </button>
            </div>

            <div *ngIf="isTestStarted" class="col-md-12 start-test-container">
              <div class="col-md-12 timer-container">
                <div>
                  <div *ngIf="!isPositiveValue" class="text-container">
                    <p class="text">{{ 'app.walk.wait' | translate }}</p>
                  </div>
                  <div *ngIf="isPositiveValue && !isTestStoped" class="loading-container">
                    <app-spinner [size]="30"></app-spinner>
                    <span>{{ 'app.walk.progessTest' | translate }}</span>
                  </div>
                </div>

                <div>
                  <span
                    class="timer"
                    [ngClass]="{ 'positive-value': isPositiveValue, 'negative-value': !isPositiveValue }"
                  >
                    {{ timerWalk.minutesString }}:{{ timerWalk.secondesString }}
                  </span>
                </div>
              </div>

              <div *ngIf="!isPositiveValue" class="col-md-12 info-test-container">
                <img class="img-stop" src="assets/images/images/walk_stop.svg" />
                <p class="info-test">{{ 'app.walk.waitBis' | translate }}</p>
              </div>

              <div
                *ngIf="isPositiveValue && !isTestStoped"
                class="col-md-12 justify-content-center align-content-center button-container-stop"
              >
                <button class="btn btn-fifth general-button" (click)="stopTest()">
                  {{ 'app.walk.stopTest' | translate }}
                </button>
              </div>

              <div *ngIf="isTestStoped" class="col-md-12 info-test-container">
                <img class="img-stop" src="assets/images/images/walk_stop.svg" />
                <p class="info-test">{{ 'app.walk.waitBisAfter' | translate }}</p>
              </div>
            </div>

            <div *ngIf="isTestStoped" class="col-md-12 start-test-container">
              <div>
                <div class="col-md-12 timer-container">
                  <div *ngIf="!isPositiveValue" class="text-container">
                    <p class="text">{{ 'app.walk.waitBeforeStop' | translate }}</p>
                  </div>
                  <span class="timer" [ngClass]="'negative-value'">
                    {{ timerStopWalk.minutesString }}:{{ timerStopWalk.secondesString }}
                  </span>
                </div>
              </div>

              <div>
                <div *ngIf="isTestStoped" class="col-md-12 info-test-container">
                  <img class="img-stop" src="assets/images/images/walk_stop.svg" />
                  <p class="info-test">{{ 'app.walk.waitBisAfter' | translate }}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-4 simple-content-component" *ngIf="cameraConfigured">
            <div>
              <div>{{ 'app.walk.camera' | translate }}</div>
            </div>
            <div>
              <app-webcam-connexion #webCamMonitor></app-webcam-connexion>
            </div>
          </div>
        </div>
      </div>
      <app-walk-assessment
        *ngIf="canDisplayAssessment"
        class="col-md-9 fried-info"
        (getAssessments)="onGetAssessments($event)"
        (getStepNumber)="onGetStepNumber($event)"
        (replayTest)="onReplayTest()"
        (finsihAnalysis)="onFinsihAnalysis($event)"
        (modifyAppreciation)="onModifyAppreciation()"
        [currentWalkRecordingSession]="currentWalkRecordingSession"
      >
      </app-walk-assessment>
    </div>

    <div class="row">
      <div class="col-md-12 footer-content-component">
        <button #btnOpenAppreciation data-target="#walkAppreciationModal" data-toggle="modal" [hidden]="true"></button>
        <button
          *ngIf="!canDisplayAssessment"
          class="btn btn-primary right-button"
          [disabled]="!isTestFinish"
          data-target="#walkAppreciationModal"
          data-toggle="modal"
        >
          {{ 'Continuer' | translate }}
        </button>

        <button *ngIf="canDisplayAssessment" class="btn btn-primary right-button" (click)="validateAssessment()">
          {{ 'button.validation' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- START MODALS -->

<div
  class="modal fade"
  id="walkTutorialModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="walkTutorialModalTitle"
  aria-hidden="true"
  #walkTutorialModal
>
  <div class="modal-dialog modal-dialog-centered modal-large" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="walkTutorialModalTitle">
          {{ 'Installation' | translate }}
        </h5>
        <button #btnClose type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-walk-tutorial #walkTutorial></app-walk-tutorial>
      </div>
      <div class="modal-footer d-flex justify-content-end">
        <button class="btn btn-primary" (click)="onCloseModal()">{{ 'Close' | translate }}</button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="walkAppreciationModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="walkAppreciationModalTitle"
  aria-hidden="true"
  #walkAppreciationModal
>
  <div class="modal-dialog modal-dialog-centered modal-large" role="document">
    <div class="modal-content modal-appreciation">
      <div class="modal-header">
        <h5 class="modal-title" id="walkAppreciationModalTitle">
          {{ 'Appreciation visuelle' | translate }}
        </h5>
        <button #btnCloseAppreciation type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-walk-appreciation #walkAppreciation (validate)="onValidate($event)"> </app-walk-appreciation>
      </div>
    </div>
  </div>
</div>

<app-core-modal name="{{ waitAnalyseName }}" #measureWaitAnalyse>
  <div class="modal-body flex-column align-items-center">
    <label>{{ 'app.test.mesure.inprogress.modal.contentanalyses' | translate }}</label>
    <app-spinner></app-spinner>
  </div>
</app-core-modal>

<button #btnOpenQuit data-target="#walkQuitTestModal" data-toggle="modal" [hidden]="true"></button>
<div
  class="modal fade"
  id="walkQuitTestModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="walkQuitTestModalTitle"
  aria-hidden="true"
  #walkQuitTestModal
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="walkQuitTestModalModalTitle">
          {{ 'app.walk.impossible.quit.title' | translate }}
        </h5>
        <button #btnCloswalkQuitTestModal type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {{ 'app.walk.impossible.quit.message' | translate }}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal">{{ 'Ok' | translate }}</button>
      </div>
    </div>
  </div>
</div>

<button #btnQuitBeforeSave data-target="#walkQuitBeforeSaveModal" data-toggle="modal" [hidden]="true"></button>
<div
  class="modal fade"
  id="walkQuitBeforeSaveModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="walkQuitBeforeSaveModalTitle"
  aria-hidden="true"
  #walkQuitBeforeSaveModal
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="walkQuitBeforeSaveModalModalTitle">
          {{ 'app.quit.before.save.title' | translate }}
        </h5>
        <button #btnCloswalkQuitBeforeSaveModal type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p [innerHTML]="'app.walk.impossible.quit.before.save.message' | translate"></p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-tertiary" (click)="goTo()" data-dismiss="modal">
          {{ 'app.quit.before.save.button.yes' | translate }}
        </button>
        <button type="button" class="btn btn-primary" data-dismiss="modal">{{ 'Non' | translate }}</button>
      </div>
    </div>
  </div>
</div>
