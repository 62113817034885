import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class VideoDownloadService {
  constructor(private http: HttpClient) {}

  getVideoUrl(url: string): Promise<string> {
    const headers = new HttpHeaders({
      //Authorization: 'Bearer YOUR_ACCESS_TOKEN', // Remplacez par votre token d'autorisation
    });

    return (
      this.http
        //.get(url, { headers, responseType: 'blob' })
        .get(url, { responseType: 'blob' })
        .toPromise()
        .then((response) => {
          const blob = new Blob([response], { type: 'video/webm' });
          return window.URL.createObjectURL(blob);
        })
    );
  }

  downloadVideo(url: string): void {
    const headers = new HttpHeaders({
      Authorization: 'Bearer YOUR_ACCESS_TOKEN', // Remplacez par votre token d'autorisation
    });
    this.http
      .get(url, { headers, responseType: 'blob' }) // `blob` pour télécharger le fichier binaire
      .subscribe((response) => {
        const blob = new Blob([response], { type: 'video/webm' });
        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = 'recording.webm'; // Nom du fichier téléchargé
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(downloadUrl);
      });
  }
}
