import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { marker as i18nKey } from '@biesbjerg/ngx-translate-extract-marker';
import { BehaviorSubject, Subject } from 'rxjs';
import { ForcePlatformOrchestrator } from 'src/app/modules/romberg/services/orchestrator.service';
import { AnalysisResults } from 'src/app/modules/walk/models/analysis-results.model';
import { Assessment, WalkAssessmentType } from 'src/app/modules/walk/models/assessment.model';
import { AnalysisResultsRepository } from 'src/app/modules/walk/services/analysis-result-repository.service';
import { AssessmentItem, assessmentItems } from './walk-assessment-item.model';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { VideoDownloadService } from 'src/app/modules/walk/services/video-download-service';

@Component({
  selector: 'app-walk-assessment',
  templateUrl: './walk-assessment.component.html',
  styleUrls: ['./walk-assessment.component.scss'],
  // styleUrls: ['./walk-assessment.component.scss', '../../shared/scss/common.scss'],
  providers: [AnalysisResultsRepository, ForcePlatformOrchestrator],
})
export class WalkAssessmentComponent implements OnInit, OnDestroy {
  @Output() replayTest: EventEmitter<void> = new EventEmitter<void>();
  @Output() modifyAppreciation: EventEmitter<void> = new EventEmitter<void>();
  @Output() getAssessments: EventEmitter<AssessmentItem[]> = new EventEmitter<AssessmentItem[]>();
  @Output() getStepNumber: EventEmitter<number> = new EventEmitter<number>();
  @Output() finsihAnalysis: EventEmitter<AnalysisResults> = new EventEmitter();
  @Input() currentWalkRecordingSession;

  @ViewChild('btnWalkGraphics', { static: true }) btnWalkGraphics: ElementRef;

  public items: AssessmentItem[] = assessmentItems;

  public assessments: Assessment[];
  public histo: boolean;

  public currentDate: string;
  public comparedAssessments$ = new BehaviorSubject<Assessment[]>([]);

  public bilanType = 3;
  public currentPatientId: number;
  private unsubscribe = new Subject<void>();
  public resultsWalk: AnalysisResults = null;
  public isGraphicZoomed: boolean = false;

  public titleGraphic: string = '';

  public videoUrl: string;
  public videoSrc: SafeUrl | null = null;
  public hasVideo: boolean = false;

  constructor(
    private router: Router,
    private analysisResultsRepository: AnalysisResultsRepository,
    private platformOrchestrator: ForcePlatformOrchestrator,
    private videoDownloadService: VideoDownloadService,
    private sanitizer: DomSanitizer,
  ) {}

  public ngOnDestroy() {
    // notify to unsubscribe observable register with takeUntil(this.unsubscribe)
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.resultsWalk = null;
  }
  public ngOnInit(): void {
    this.assessments = this.prepareAssessments();
    this.comparedAssessments$.next([]);
    const guid = this.currentWalkRecordingSession;
    this.analysisResultsRepository.get(guid).then((res) => {
      this.resultsWalk = res;
      if (this.resultsWalk.graphes.video != undefined) {
        this.videoUrl = this.resultsWalk.graphes.video;
        this.loadVideo();
        this.hasVideo = true;
      }
      this.attributeResultToItems(this.resultsWalk, this.items);
      this.getAssessments.emit(this.items);
      this.getStepNumber.emit(this.getStepNumberFromResults(this.resultsWalk));
      this.finsihAnalysis.emit(this.resultsWalk);
      // this.btnWalkGraphics.nativeElement.click();
    });
  }

  loadVideo(): void {
    this.videoDownloadService.getVideoUrl(this.videoUrl).then((url) => {
      this.videoSrc = this.sanitizer.bypassSecurityTrustUrl(url);
    });
  }

  downloadVideo(): void {
    this.videoDownloadService.downloadVideo(this.videoUrl);
  }

  /**
   * Method to get only interesting walking results and attribute them to the AssesmentItem[] variable.
   */
  private attributeResultToItems(results: AnalysisResults, items: AssessmentItem[]) {
    items.forEach((item) => {
      switch (item.title) {
        case i18nKey('app.walk.assessment.averageSpeed'): {
          item.value = results.AvgSpeed;
          break;
        }
        case i18nKey('app.walk.assessment.averageStep'): {
          item.value = results.MeanStepDuration;
          break;
        }
        case i18nKey('app.walk.assessment.variabilityDuration'): {
          item.value = results.CycleVariability;
          break;
        }
        case i18nKey('app.walk.assessment.pelvicRoll'): {
          item.value = results.RoliTronc;
          break;
        }
        case i18nKey('app.walk.assessment.walkingSymmetry'): {
          item.value = results.SwingMeanStrideDuration;
          break;
        }
        case i18nKey('app.walk.assessment.doublePressTime'): {
          item.value = Number(results.DoubleStance * 100);
          break;
        }
      }
    });
  }

  private getStepNumberFromResults(results: AnalysisResults): number {
    return results.graphes.semioInfo.leftStep + results.graphes.semioInfo.rightStep;
  }

  private prepareAssessments(): Assessment[] {
    const assessments = [
      WalkAssessmentType.Hesitant,
      WalkAssessmentType.SmallSteps,
      WalkAssessmentType.Irregular,
      WalkAssessmentType.Asymetric,
      WalkAssessmentType.SwingingArms,
      WalkAssessmentType.Balance,
    ].map((type) => {
      const assessment = new Assessment();
      assessment.type = type;
      assessment.nonEvaluated = true;
      return assessment;
    });
    return assessments;
  }

  public onCanLaunchTest(): void {}

  public replay(): void {
    this.replayTest.emit();
  }

  public modify(): void {
    this.modifyAppreciation.emit();
  }

  public onChangeStatusGraphics(isGraphicZoomed: boolean): void {
    this.isGraphicZoomed = isGraphicZoomed;
  }

  public onChangeTitle(titleGraphic: string) {
    this.titleGraphic = titleGraphic;
  }
}
