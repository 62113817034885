import {
  Course,
  PatientControllerService,
  PatientDetail,
  WalkingControllerService,
  WalkingWithRelations,
} from '@abilycare/dal-client';
import { Component, EventEmitter, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { marker as i18nKey } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, from as fromPromise, Observable, Subject, Subscription } from 'rxjs';
import { HistoVersusConsultationService } from 'src/app/modules/consultation';
import { ShoesSelectorValues } from 'src/app/modules/force-platform';
import {
  AnalysisResults,
  AnalysisResults as AnalysisResultsWalk,
} from 'src/app/modules/walk/models/analysis-results.model';
import { Assessment, WalkAssessmentType } from 'src/app/modules/walk/models/assessment.model';
import { AnalysisResultsRepository } from 'src/app/modules/walk/services/analysis-result-repository.service';
import { WalkOperation, WalkOperationService } from 'src/app/modules/walk/services/walk-operation.service';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { ModalComponent } from 'src/app/view/common/modal/modal.component';
import { AssessmentItem, assessmentItems } from '../walk-assessment/walk-assessment-item.model';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { VideoDownloadService } from 'src/app/modules/walk/services/video-download-service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-walk-view',
  templateUrl: './walk-view.component.html',
  styleUrls: ['./walk-view.component.scss'],
  providers: [AnalysisResultsRepository, WalkOperationService, HistoVersusConsultationService],
})
export class WalkViewComponent implements OnInit, OnDestroy {
  @ViewChildren(ModalComponent) public modals: QueryList<ModalComponent>;

  @Output() getAssessments: EventEmitter<AssessmentItem[]> = new EventEmitter<AssessmentItem[]>();
  @Output() finsihAnalysis: EventEmitter<AnalysisResults> = new EventEmitter();
  @Output() getStepNumber: EventEmitter<number> = new EventEmitter<number>();

  public patient: PatientDetail;
  public returnTitle: string = i18nKey('Checkup.navbar.history.back');
  public shoesSelectorValues: ShoesSelectorValues;
  public walkAssessments: AssessmentItem[];

  public items: AssessmentItem[] = assessmentItems;
  public index: number;

  public lang: string;

  public error: boolean = false;
  private retryResponse = new Subject<boolean>();

  private subscriptions: Subscription[] = [];

  public currentWalkRecordingSession;

  public readonly waitAnalyseName = 'WaitAnalyse';
  public waitMessage = new BehaviorSubject<string>('');
  public readonly resultSnackBarName = 'SuccessSnackBar';

  public resultsWalk: AnalysisResultsWalk = null;
  public typeRapport: string = 'Walk';
  public testName: string = i18nKey('navbar.walkTest');

  public assessments: Assessment[];
  public histo: boolean;
  public fromBilan: boolean;

  public currentDate: string;
  //public comparedAssessments$ = new BehaviorSubject<Assessment[]>([]);

  public newPage: string = '';

  public titleGraphic: string = '';

  public testData: any;
  public walkToCompare: any;
  public listTestWalking: any;

  public fromCourse: boolean;
  public course: Course;

  public videoUrl: string;
  public videoSrc: SafeUrl | null = null;
  public hasVideo: boolean = false;

  constructor(
    private dataService: DataProviderService,
    private operationService: WalkOperationService,
    private translateService: TranslateService,
    private videoDownloadService: VideoDownloadService,
    private router: Router,
    public histoVerusConsultationService: HistoVersusConsultationService,
    private analysisResultsRepository: AnalysisResultsRepository,
    private location: Location,
    private route: ActivatedRoute,
    private walkingService: WalkingControllerService,
    private patientService: PatientControllerService,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit() {
    this.lang = this.translateService.currentLang;

    this.patient = this.dataService.retrievePatient();

    this.route.queryParams.subscribe((params) => {
      this.fromCourse = params.fromCourse == 1;
    });
    const objState: any = this.location.getState();
    let obj: any;
    if (this.fromCourse) {
      this.course = objState[0];
      obj = objState[1];
      this.returnTitle = i18nKey('Checkup.navbar.patient.back');
    } else {
      obj = objState;
    }

    console.log('obj is ', obj);
    this.hasVideo = obj.hasVideo;

    this.patientService.patientControllerFindById(obj.patientId).subscribe((p) => {
      this.patient = p;
      this.dataService.setPatient(p);
      this.walkingService.walkingControllerFindAllWalkingByPatient(this.patient.id).subscribe((res) => {
        this.listTestWalking = res;
        this.listTestWalking = this.listTestWalking.filter(function(e) {
          return !(this.testData.id == e.id);
        });
      });
    });

    this.route.queryParams.subscribe((params) => {
      this.fromBilan = params.fromBilan == 1;
    });

    this.currentWalkRecordingSession = obj.id;
    this.testData = obj;
    this.shoesSelectorValues = obj.shoesSelectorValues;
    this.operationService.setCurrentOperation(WalkOperation.Measure);
    this.retryResponse.next(false);

    this.assessments = this.prepareAssessments();
    //this.comparedAssessments$.next([]);
    const guid = this.currentWalkRecordingSession;
    this.analysisResultsRepository.get(guid).then((res) => {
      this.resultsWalk = res;
      console.log('this.resultsWalk  is ', this.resultsWalk);
      this.videoUrl = this.resultsWalk.graphes.video;
      this.loadVideo();
      this.attributeResultToItems(this.resultsWalk, this.items);
      this.getAssessments.emit(this.items);
      this.getStepNumber.emit(this.getStepNumberFromResults(this.resultsWalk));
      this.finsihAnalysis.emit(this.resultsWalk);
      // this.shoesSelectorValues =
      // this.btnWalkGraphics.nativeElement.click();
    });
  }

  loadVideo(): void {
    this.videoDownloadService.getVideoUrl(this.videoUrl).then((url) => {
      this.videoSrc = this.sanitizer.bypassSecurityTrustUrl(url);
    });
  }

  downloadVideo(): void {
    this.videoDownloadService.downloadVideo(this.videoUrl);
  }

  private attributeResultToItems(results: AnalysisResults, items: AssessmentItem[]) {
    items.forEach((item) => {
      switch (item.title) {
        case i18nKey('app.walk.assessment.averageSpeed'): {
          item.value = results.AvgSpeed;
          break;
        }
        case i18nKey('app.walk.assessment.averageStep'): {
          item.value = results.MeanStepDuration;
          break;
        }
        case i18nKey('app.walk.assessment.variabilityDuration'): {
          item.value = results.CycleVariability;
          break;
        }
        case i18nKey('app.walk.assessment.pelvicRoll'): {
          item.value = results.RoliTronc;
          break;
        }
        case i18nKey('app.walk.assessment.walkingSymmetry'): {
          item.value = results.SwingMeanStrideDuration;
          break;
        }
        case i18nKey('app.walk.assessment.doublePressTime'): {
          item.value = Number(results.DoubleStance * 100);
          break;
        }
      }
    });
  }

  private getStepNumberFromResults(results: AnalysisResults): number {
    return results.graphes.semioInfo.leftStep + results.graphes.semioInfo.rightStep;
  }

  private prepareAssessments(): Assessment[] {
    const assessments = [
      WalkAssessmentType.Hesitant,
      WalkAssessmentType.SmallSteps,
      WalkAssessmentType.Irregular,
      WalkAssessmentType.Asymetric,
      WalkAssessmentType.SwingingArms,
      WalkAssessmentType.Balance,
    ].map((type) => {
      const assessment = new Assessment();
      assessment.type = type;
      assessment.nonEvaluated = true;
      return assessment;
    });
    return assessments;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptions = null;
  }

  public onChangeTitle(titleGraphic: string) {
    this.titleGraphic = titleGraphic;
  }

  public onSelectPage(newPage: string) {
    this.newPage = newPage;
  }

  public goTo() {
    this.router.navigate([this.newPage]);
  }

  public setWalkToCompare(value: string): void {
    this.walkToCompare = value;
    this.error = false;
    this.doCompare();
  }

  public doCompare() {
    const res = [this.testData, this.walkToCompare];
    if (!this.walkToCompare) this.error = true;
    else this.router.navigateByUrl('/courses/fried/walkComparison', { state: res });
  }
}
