<app-fried-history-navbar
  [patient]="patient"
  [returnTitle]="returnTitle"
  (selectPage)="onSelectPage($event)"
  *ngIf="!fromCourse"
></app-fried-history-navbar>
<app-custom-navbar
  [patient]="patient"
  [returnTitle]="returnTitle"
  (selectPage)="onSelectPage($event)"
  *ngIf="fromCourse"
></app-custom-navbar>
<app-fried-custom-navbar [testName]="testName" [testData]="testData" *ngIf="!fromCourse" [displayDoubleTask]="true">
</app-fried-custom-navbar>
<app-courses-breadcrumb-navbar
  [testName]="testName | translate"
  [course]="course"
  *ngIf="fromCourse"
></app-courses-breadcrumb-navbar>
<div class="col-md-12">
  <div class="row">
    <div class="col-md-3 p-0" *ngIf="fromCourse">
      <app-fried-navbar [currentPage]="'walk'" (selectPage)="onSelectPage($event)" [course]="course"></app-fried-navbar>
    </div>
    <div class="col-md-9 main-content">
      <div class="row">
        <div class="col-md-7 simple-content-component" style="height: 50%;">
          <div>{{ 'Résultats' | translate }}</div>
          <div class="result-summary">
            <div class="row">
              <div class="col-lg-6" *ngFor="let item of items; let index = index">
                <div class="col-md-12 flex-column assessment-container" *ngIf="index % 2 == 0">
                  <app-walk-assessment-item [item]="item"> </app-walk-assessment-item>
                </div>
                <div class="col-md-12 flex-column assessment-container" *ngIf="index % 2 != 0">
                  <app-walk-assessment-item [item]="item"> </app-walk-assessment-item>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div *ngIf="error && !fromCourse" class="col-md-12 alert alert-danger">
            <div>{{ 'App.comparison.alert' | translate }}</div>
          </div>
          <div class="graphics simple-content-component flex-column" *ngIf="!fromCourse">
            <span style="text-align: center; color: #1e62d6; text-transform: uppercase;">
              {{ 'App.comparison' | translate }}
            </span>
            <select [ngModel]="walkToCompare" (ngModelChange)="setWalkToCompare($event)">
              <ng-container *ngFor="let walkobj of listTestWalking">
                <ng-container *ngIf="walkobj.id !== testData.id">
                  <option [ngValue]="walkobj">
                    {{ walkobj.date | localdate: 'datetime' }} {{ walkobj.doubleTask ? ' *' : '' }}
                  </option>
                </ng-container>
              </ng-container>
            </select>
          </div>
          <div class="graphics simple-content-component flex-column" *ngIf="videoSrc && hasVideo && !walkToCompare">
            <button class="btn btn-primary" (click)="downloadVideo()">
              {{ 'app.download.video' | translate }}
            </button>
            &nbsp;
            <video *ngIf="videoSrc" controls width="100%" height="100%">
              <source [src]="videoSrc" type="video/webm" />
              {{ 'app.see.video' | translate }}
            </video>
          </div>
          <div class="graphics simple-content-component flex-column">
            <img
              class="pointer"
              src="assets/images/images/walk_graphic.svg"
              data-target="#walkGraphicsModal"
              data-toggle="modal"
              alt=""
            />
            <button class="btn btn-tertiary" data-target="#walkGraphicsModal" data-toggle="modal">
              {{ 'app.walk.access.graphic' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- graph model walk -->
<div
  class="modal fade"
  id="walkGraphicsModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="walkGraphicsModalTitle"
  aria-hidden="true"
  #walkGraphics
>
  <div class="modal-dialog modal-dialog-centered modal-extra-large" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="walkgraphicsModalModalTitle">
          {{ titleGraphic }}
        </h5>
        <button #btnCloseGraphics type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-walk-graphics
          *ngIf="resultsWalk"
          [resultsWalk]="resultsWalk"
          (changeTitle)="onChangeTitle($event)"
        ></app-walk-graphics>
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary" data-dismiss="modal" aria-label="Close">{{ 'Close' | translate }}</button>
      </div>
    </div>
  </div>
</div>
