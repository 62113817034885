import { catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

/* import { LocalStorageObject, LocalStorageEx } from 'app/tools/localstorageobject.enum';*/
import { AppConfigurationService } from 'src/app/app-configuration.service';
import { InterceptorHttpParams } from '../../core/services/http/interceptor-http-params';

interface IGetWebCamState {
  GetCameraStateResult: string;
}

@Injectable()
export class WebCamService {
  private pushPathMeasure: string = '/pushVideo?guid={guid}&date={date}';
  private walkProxyBaseUri: string = this.appConfigurationService.locomotionProxyBaseUri;

  constructor(private http: HttpClient, private appConfigurationService: AppConfigurationService) {
    this.walkProxyBaseUri = this.appConfigurationService.locomotionProxyBaseUri;
  }

  public pushVideo(currentRecordingSessionId: string, recordDate: string, video: Blob): Observable<any> {
    let url = this.walkProxyBaseUri + '/rawFiles/UploadVideo';
    url += '?guid=' + currentRecordingSessionId;
    url += '&filename=' + 'recording.webm';

    console.log('Body type: %s, Body length: %d', typeof video, video.size || 0);

    console.log('push video url is ', url);

    const formData = new FormData();
    formData.append('video', video, 'recording.webm');
    const params = new InterceptorHttpParams({ noNotificationOnError: true });

    const req = new HttpRequest('POST', url, video, {
      headers: new HttpHeaders({
        'Content-Type': 'application/octet-stream',
      }),
      params: params,
    });

    return this.http.request(req).pipe(
      tap((response) => {
        console.log('HTTP response received:', response);
      }),
      catchError((error) => {
        console.error('HTTP error occurred:', error);
        return throwError(error);
      }),
    );
  }
}
