<ng-container>
  <div class="video-container" *ngIf="!cameraFound">
    <img src="assets/images/images/logitech.jpg" alt="" />
  </div>
  <div class="col-md-11  full-size-content-component" style="font-weight: normal;" *ngIf="!cameraFound">
    <img class="d-inline-block align-top" width="20" height="20" src="assets/images/icons/info-circle.svg" alt="" />
    <span style="margin-left: 10px;">{{ webcamMessage }}</span>
  </div>
  <div class="video-container" *ngIf="cameraFound">
    <video #videoElement autoplay playsinline></video>
  </div>
  <div class="controls" *ngIf="config">
    <div class="button-container">
      <button class="btn btn-primary" (click)="scanAndConfigureCamera()">
        Connect Camera
      </button>
      <button class="btn btn-primary" (click)="disconnectCamera()">
        Forget Camera
      </button>
    </div>
    <div class="button-container">
      <span style="margin-left: 10px;" *ngIf="config">{{ configMessage }}</span>
    </div>
  </div>
  <div class="controls" *ngIf="!config && cameraFound">
    <div class="checkbox-container">
      <label>
        <input type="checkbox" [(ngModel)]="recordCamera" />
        {{ 'app.walk.recordCamera' | translate }}
      </label>
    </div>
    <!--
    <div class="button-container">
      <button (click)="startRecording()" [disabled]="isRecording">Démarrer l'enregistrer</button>
      <button (click)="stopRecording()" [disabled]="!isRecording">saveToServeur</button>
    </div>
    <a #downloadLink style="display:none"></a> 
-->
  </div>
</ng-container>
