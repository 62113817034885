import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/modules/core/services/authentification/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { LANGUAGES } from 'src/app/tools/divers.const';

@Component({
  selector: 'app-login-error',
  templateUrl: './login-error.component.html',
  styleUrls: ['./login-error.component.scss'],
})
export class LoginErrorComponent implements OnInit {
  public cguHtml: string = '';
  constructor(
    private translateService: TranslateService,
    private router: Router,
    private snackBarService: SnackBarService,
    private dataService: DataProviderService,
    private authService: AuthService,
  ) {
    if (this.dataService) {
      this.dataService.storeHeaderShowed(false);
      let currentLanguage = this.dataService.getLanguage();
      if ((currentLanguage = null)) {
        this.translateService.setDefaultLang(LANGUAGES[2]);
        this.translateService.use(LANGUAGES[2]);
      }
    } else {
      this.translateService.setDefaultLang(LANGUAGES[2]);
      this.translateService.use(LANGUAGES[2]);
    }
  }

  public async ngOnInit() {
    // Nothing
    //localStorage.clear();
    //console.log('currentLanguage dans login: NgInit ', this.translateService.getDefaultLang());
    this.translateService.get('CGU.Content').subscribe((translated: string) => {
      this.cguHtml = this.translateService.instant('CGU.Content');
    });
  }
}
