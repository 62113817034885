import { Component, HostListener, OnDestroy, OnInit, QueryList, ViewChild } from '@angular/core';
import { AuthService } from './modules/core/services/authentification/auth.service';
import { Router } from '@angular/router';
import { filter, first } from 'rxjs/operators';
import { LANGUAGES } from './tools/divers.const';
import { Idle, IdleExpiry, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { TranslateService } from '@ngx-translate/core';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { VersionService } from './modules/core/services/version/version.service';
import { PractitionerControllerService } from '@abilycare/dal-client';
import { ModalComponent } from './view/common/modal/modal.component';
import { NavbarComponent } from './view/navbar/navbar.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Abilycare';

  private idleSeconds: number = 3600;
  private idleWatched: boolean = false;
  public isLogged: boolean = false;
  public isDisabled: boolean = false;

  private subscriptions: Subscription[] = [];
  @ViewChild('navBar', { static: true }) navBar: NavbarComponent;

  private readonly redirectUrlAfterFirstLogin: string = '/Patient';
  constructor(
    private router: Router,
    private authService: AuthService,
    private idle: Idle,
    private translate: TranslateService,
    private dataService: DataProviderService,
    private versionService: VersionService,
    private practitionnerService: PractitionerControllerService,
  ) {
    this.subscriptions.push(
      authService.init().subscribe(() => {
        // auth init done
        // auth init done
        // if login fail redirect to login
        this.subscriptions.push(
          authService.isDisabled.subscribe((isDisabled) => {
            this.isDisabled = isDisabled;
            if (isDisabled) {
              authService.isConnected.next(false);
              this.router.navigate(['/loginError']);
            } else {
              authService.isConnected.subscribe((isLogged) => {
                this.isLogged = isLogged;
                if (!isLogged) {
                  this.router.navigate(['/login']);
                }
              });
            }
          }),
        );

        // only redirect for first login.
        this.subscriptions.push(
          authService.isConnected
            .pipe(
              filter((isLogged) => isLogged),
              first(),
            )
            .subscribe(() => {
              //update version and date
              this.practitionnerService
                .practitionerControllerUpdateMyLastConnection(this.versionService.getAppVersion())
                .subscribe(() => {
                  //console.log('last connection update for me');
                });
              if (this.authService.stateUrl) {
                this.router.navigate([this.authService.stateUrl]);
              } else {
                const wasUrl = this.router.url;
                // only redirect from default route (need improvement)
                if (wasUrl === '/' || wasUrl === '/login' || wasUrl === '' || wasUrl.length > 100) {
                  this.router.navigate([this.redirectUrlAfterFirstLogin]);
                }
              }
            }),
        );
      }),
    );

    translate.addLangs(LANGUAGES);
    translate.setDefaultLang(LANGUAGES[2]);
    const currentLanguage = this.dataService.getLanguage();
    if (currentLanguage != null) {
      translate.use(currentLanguage);
    } else {
      translate.getBrowserLang()
        ? translate.use(translate.getBrowserLang())
        : translate.use(translate.getDefaultLang());
    }
    this.idleWatch(this.idleSeconds);
  }

  public ngOnInit(): void {
    this.authService.appVersionDisplayModal.subscribe((value) => {
      if (value) {
        console.log('show release note ', value);
        this.showVersionModal();
      }
    });
  }

  public showVersionModal() {
    this.navBar.showVersionModal();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptions = null;
  }

  public idleWatch(idleSeconds: number) {
    if (this.idleWatched === true) return;
    this.idle.setIdle(5);
    this.idle.setTimeout(idleSeconds);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.subscriptions.push(
      this.idle.onTimeout.subscribe(() => {
        console.warn('Idle timeouted !');
        this.isLogged = false;
        this.authService.logOut();
        this.idle.watch();
      }),
    );
    this.subscriptions.push(
      this.idle.onIdleStart.subscribe(() => {
        console.log('Idle begins at ' + new Date().toISOString());
      }),
    );
    this.subscriptions.push(
      this.idle.onIdleEnd.subscribe(() => {
        console.log('Idle ends at ' + new Date().toISOString());
      }),
    );
    this.subscriptions.push(
      this.idle.onTimeoutWarning.subscribe((countdown: number) => {
        if (countdown < 5) {
          console.warn('Idle counts down: ' + countdown + ' seconds');
        }
      }),
    );

    this.idle.watch();
    this.idleWatched = true;
  }

  public logOut() {
    //localStorage.clear();
    this.authService.logOut();
    this.router.navigate(['/login']);
  }

  public cancelLogOut() {
    this.authService.cancelLogOut();
  }

  /**
   * load or reload event
   */
  @HostListener('window:load', ['$event'])
  public load($event) {
    this.idleWatch(this.idleSeconds);
  }

  /**
   * refresh, unload and close event
   */
  @HostListener('window:beforeunload', ['$event'])
  public beforeUnloadHander($event) {
    // this.oauthService.stopRefreshTimer();
    this.idle.stop();
    this.idleWatched = false;
    /**
     * This line will show a dialogue windows as does alert();
     */
    // $event.returnValue = 'beforeUnloadHander';
  }
}
